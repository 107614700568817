@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.password {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
  margin-top: 50px;
  margin-bottom: 50px;
}

/* Adjust the bottomWrapper's margin to match modals without the helper text */
.bottomWrapperText {
  composes: marketplaceModalBottomWrapperText from global;
  margin-top: 21px;
}

.recoveryLink {
  color: #404145;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.recoveryLinkInfo {
  color: #a6a6a6;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.mainContainer {
  display: flex;
  height: 100%;
  @media (max-width: 767px) {
    flex-direction: column-reverse;
  }
}
.leftContainer {
  background: linear-gradient(180deg, #2fa674 0%, #146241 100%);
  height: 100%;
  display: flex;
  max-width: 50%;
  @media (max-width: 767px) {
    max-width: unset;
  }
  @media (max-width: 575px) {
    flex-direction: column;
  }
}
.innerRight {
  /* @media (max-width: 767px) { */
  display: flex;
  align-items: center;
  /* } */
}
.rightContainer {
  /* background-color: red; */
  height: 100%;
  @media (min-width: 768px) and (max-width: 1244px) {
    width: 320px;
    margin-left: -48px;
  }
  @media (max-width: 767px) {
    margin-top: unset;
  }
}

.signUpBannerImg {
  margin-top: 210px;
}

.headingSection {
  max-width: 406px;
  color: #fff;
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.innerLft {
  padding: 98px 0 98px 98px;
  @media (max-width: 1199px) {
    padding-left: 30px;
  }
  @media (max-width: 767px) {
    padding-top: 30px;
  }
}

.pointContainer {
  margin-top: 35px;

  display: flex;
}

.iconSection {
  margin-right: 13px;
  & svg {
    fill: none;
  }
}

.labelContainer {
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  max-width: 289px;
}

.rightContainer {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 1366px) {
    padding: 0 30px;
    width: 50%;
  }
  @media (max-width: 767px) {
    width: 100%;
  }
}

.signupHeader {
  color: #404145;
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 88px;
  @media (max-width: 767px) {
    margin-top: 50px;
  }
}

.loginlink {
  color: #404145;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 23px 0 58px 0;
  & span {
    color: #404145;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-decoration-line: underline;
    cursor: pointer;
  }
}

.signUpModuleContainer {
  display: flex;
  flex-direction: column;
  max-width: 447px;
}

.signUpModule {
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  background: #fff;
  display: flex;
  height: 54px;
  flex-shrink: 0;
  padding: 0 27px;
  /* flex-wrap: wrap; */
  align-items: center;
  margin-bottom: 23px;
  cursor: pointer;
  width: 420px;
  @media (max-width: 575px) {
    justify-content: space-between;
    padding: 0 18px;
    width: 320px;
  }
}
.moduleLabel {
  margin: 0 88px;
  color: #404145;
  flex-grow: 1;
  text-align: center;
  white-space: nowrap;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  @media (max-width: 575px) {
    margin: 0 8px;
  }
}

.moduleOr {
  margin: 6px auto 34px;
  color: #b8b8b8;

  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.termsSection {
  color: #818181;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 21px;
  max-width: 447px;
  @media (max-width: 767px) {
    margin-bottom: 21px;
  }
}

.back {
  margin-top: 32px;
  color: #404145;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
  & span {
    width: 30px;
    height: 25px;
    flex-shrink: 0;
    margin-right: 9px;
  }
}

.heading {
  color: #404145;
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 31px;
  margin-bottom: 36px;
}
